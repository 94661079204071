.footer {
  position: absolute;
  z-index: 2;
  bottom: 0;
  background: rgba(244, 244, 244, 0.28);
  width: 100%;
  min-height: 177px;
  padding: 40px 40px 15px;
  box-sizing: border-box;

  @media screen and (max-width: 767px) {
    padding: 20px 20px 15px;
  }

  &_texto {
    width: 60%;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;

    @media screen and (max-width: 1024px) {
      width: 100%;
      padding: 0;
    }

    &--slider {
      h2 {
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 25px;
      }

      p {
        font-weight: $normal;
        font-size: 18px;
        line-height: 24px;
      }

      @media screen and (max-width: 767px) {
        h2 {
          font-size: 18px;
          line-height: 22px;
        }

        p {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .slick-dots {
      width: 100%;
      text-align: center;
      margin-top: 10px;

      li {
        display: inline-block;

        button {
          cursor: pointer;
          display: block;
          overflow: hidden;
          text-indent: -9999em;
          white-space: nowrap;
          border: none;
          background: $padrao-texto;
          margin-right: 10px;
          width: 40px;
          height: 5px;
          padding: 0;
          outline: none;
        }

        &.slick-active {
          button {
            background: $padrao-link;
          }
        }
      }
    }
  }
}