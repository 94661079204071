.header {
  position: relative;
  z-index: 2;
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px 20px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    flex-direction: column-reverse;
    padding: 10px 20px 0;
  }

  h1 {
    font-size: 0;

    img {
      max-height: 161px;
    }

    @media screen and (max-width: 767px) {
      width: 85%;

      img {
        max-width: 100%;
        margin-top: 20px;
      }
    }
  }

  &_contact {
    ul {
      li {
        font-weight: $normal;
        font-size: 16px;
        line-height: 40px;
        letter-spacing: .6px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
          font-size: 12px;
          display: inline-flex;
        }

        svg {
          margin-right: 12px;
        }

        a {
          color: $padrao-texto;
          text-decoration: none;

          &:hover {
            color: $padrao-link;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;

      ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }

    @media screen and (max-width: 330px) {
      ul {
        flex-direction: column;
      }
    }
  }
}