// Importando a fonte
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');

// Fontes utilizadas
$font-principal: 'Roboto', sans-serif;

// Variações da fonte
$light: 300;
$normal: 400;
$semi-bold: 500;
$bold: 700;
$extra-bold: 900;

// Cores
$padrao-texto: #F4F4F4;
$padrao-link: #C0B283;