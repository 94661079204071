body {
  font-family: $font-principal;
  font-weight: $normal;
  font-size: 16px;
  color: $padrao-texto;
}

.maskBanner {
  background: black;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: .5;
}